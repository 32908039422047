import React from 'react';
import { Route, withRouter } from 'react-router-dom';
import Navigation from './components/Navigation.js';
import Introduction from './components/Introduction.js';
import Projects from './components/Projects.js';
import About from './components/About.js';
import Socials from './components/Socials.js';
import Contact from './components/Contact.js';
import Confirmation from './components/Confirmation.js'
import Footer from './components/Footer.js';
import './App.css';

function App() {
  return (
    <div className="App">
      <Navigation />
      <Socials />
      <Route exact path='/'>
        <Introduction />
        <Projects />
      </Route>
      <Route path='/about'>
        <About/>
        <Contact/>
      </Route>
      <Route path='/contact/confirmation'>
        <Confirmation />
      </Route>
      <Footer component={Socials}/> 
    </div>
  );
}

export default withRouter(App);
