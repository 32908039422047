import React from 'react';
import WildfireThumbnail from '../assets/WildfireTrackerThumbnail.jpg';
import CryptoThumbnail from '../assets/CryptoTrackerThumbnail.jpg';
import Covid19Thumbnail from '../assets/Covid19TrackerThumbnail.jpg';

function Projects() {
    return (
        <section id='projects' className='projects-section'>
            <p className='discover-recent'><span><svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 13l-7 7-7-7m14-8l-7 7-7-7" /></svg></span>Discover my recent projects</p>
            <h1 className='featured-title'>Featured projects</h1>
            <div className='group-container'>
                <div className='project-container'>
                    <div className='project-details'>
                        <p className='project-number'>01</p>
                        <h3 className='project-title'>Wildfire Tracker</h3>
                        <h4 className='project-summary'>Web app built using React.js, EONET NASA API, and the Google Maps API to visualize 
ongoing wildfires across the globe.</h4>
                        <p className='project-stack'>HTML | CSS | React.js</p>
                        <div className='buttons-container'>
                            <a className='view-code' href='https://github.com/songamugenzi/wildfire-tracker' target='_blank'   rel='noreferrer'>Code</a>
                            <a className='view-live' href='https://wildfire-trackr.netlify.app/' target='_blank'   rel='noreferrer'>Live</a>
                        </div>
                    </div>
                    <div className='thumbnail-container order-first'>
                        <img className='project-thumbnail' src={WildfireThumbnail} alt='phone detection app screen'/>
                    </div>
                </div>
                <div className='project-container'>
                    <div className='thumbnail-container'>
                        <img className='project-thumbnail' src={CryptoThumbnail} alt='iphone with screen on'/>
                    </div>
                    <div className='project-details'>
                        <p className='project-number'>02</p>
                        <h3 className='project-title'>Crypto Tracker</h3>
                        <h4 className='project-summary'>Cryptocurrency price tracker using React.js, Next.js, and the CoinGecko API.</h4>
                        <p className='project-stack'>HTML | CSS | React.js | Next.js</p>
                        <div className='buttons-container'>
                            <a className='view-code' href='https://github.com/songamugenzi/crypto-price-tracker' target='_blank'   rel='noreferrer'>Code</a>
                            <a className='view-live' href='https://crypto-price-trackr.netlify.app/' target='_blank'   rel='noreferrer'>Live</a>
                        </div>
                    </div>
                </div>
                <div className='project-container'>
                    <div className='project-details'>
                        <p className='project-number'>03</p>
                        <h3 className='project-title'>Covid-19 Tracker</h3>
                        <h4 className='project-summary'>Coronavirus tracker with the latest statistics of confirmed cases, recoveries, and deaths across the U.S and globally.</h4>
                        <p className='project-stack'>HTML | CSS | React.js</p>
                        <div className='buttons-container'>
                            <a className='view-code' href='https://github.com/songamugenzi/covid-19-tracker' target='_blank'   rel='noreferrer'>Code</a>
                            <a className='view-live' href='https://covid19-trackr.netlify.app/' target='_blank'   rel='noreferrer'>Live</a>
                        </div>
                    </div>
                    <div className='thumbnail-container order-first'>
                        <img className='project-thumbnail' src={Covid19Thumbnail} alt='hand holding phone with screen on'/>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Projects;