import React from 'react';

function Footer() {
    return(
        <div className='footer-section'>
            <p><span>All rights reserved. </span>Copyright ©<span> 2021</span></p>
        </div>
    );
}

export default Footer;