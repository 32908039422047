import React from 'react';
import Bitmoji from '../assets/AdobeCroppedBitmoji.png';

function Introduction() {
    return (
        <section id='intro' className='intro-section'>
            <div className='greeting'>
                <h1>Hi, I'm Songa!</h1>
                <img src={Bitmoji} alt='smiling bitmoji guy in a hat'/>
            </div>
            <h3>I'm a strategy-minded full-stack web developer passionate about crafting intuitive, dynamic applications for emerging technologies.</h3>
            <p><a className='resume-link' href='/songa-mugenzi-resume.pdf' target='_blank' rel='noreferrer'>View resume</a><span><svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" /></svg></span></p>
            <h4 className='tech-intro'>Skills</h4> 
            <div className='tech-stack'>
                <ul className='fe-skills'>
                    <li>HTML</li>
                    <li>CSS</li>
                    <li>React</li>
                    <li>JavaScript</li>
                    <li>Git</li>
                    <li>CI/CD</li>
                </ul>
                <ul className='be-skills'>
                    <li>NodeJs</li>
                    <li>Rest API</li>
                    <li>Python</li>
                    <li>PostgreSQL</li>
                    <li>SQL</li>
                    <li>NPM</li>
                </ul>
                <ul className='ux-design'>
                    <li>Figma</li>
                    <li>Sketch</li>
                    <li>InVision</li>
                    <li>Visual Design</li>
                    <li>Wireframing</li>
                    <li>Usability Testing</li>
                </ul>
            </div>
        </section>
    );
}

export default Introduction;