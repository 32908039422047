import React from 'react';

function Socials() {
    return(
        <div className='social-media'>
            <a className='linkedin-icon' href='https://www.linkedin.com/in/songamugenzi/' target='_blank' rel='noreferrer'><i className='fa fa-linkedin'></i></a>
            <a className='github-icon' href='https://github.com/songamugenzi' target='_blank' rel='noreferrer'><i className='fa fa-github'></i></a>
            <a className='email-icon' href='mailto:songa.mugenzi@gmail.com' target='_blank' rel='noreferrer'><i className='fa fa-envelope'></i></a>
            <a className='twitter-icon' href='https://twitter.com/songadev' target='_blank' rel='noreferrer'><i className='fa fa-twitter'></i></a>
        </div>
    );
}

export default Socials;