import React from 'react';
import useDarkMode from '../hooks/useDarkMode';

function Navigation() {
    const [colorTheme, setTheme] = useDarkMode();

    return (
        <nav className='nav-container'>
            <a className='logo-container' href='/'>
                <img className='logo-icon' src='https://img.icons8.com/fluent/344/programming.png' alt='computer icon'/>
            </a>
            <div className='nav-links'>
                <a className='nav-link' href='/'>Home</a>
                <a className='nav-link' href='/#projects'>Projects</a>
                <a className='nav-link' href='/about'>About</a>
                <a className='nav-link' href='/about/#contact'>Contact</a>
            </div>
            <span onClick={() => setTheme(colorTheme)} className='toggle-theme'>
                {colorTheme === 'light' ? 
                (<svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" /></svg>) :
                (<svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z" /></svg>)}
            </span>
        </nav>   
    );
}

export default Navigation;