import React from 'react';

function Confirmation() {
    return(
        <section className='success-page'>
            <div className='success-container'>
                <h3 className='success-title'>Thank you!</h3>
                <h4 className='success-message'>I'll get back to you as soon as possible.</h4>
                <p className='backto-homepage' ><a href='/' rel='noreferrer'>Return to the homepage</a><span><svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" /></svg></span></p>
            </div>
        </section>
    ); 
}

export default Confirmation;