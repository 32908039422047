import React from 'react';
import AboutMe from '../assets/AboutMeThumbnail.jpeg';
import BeachSunset from '../assets/BeachSunset.jpeg';
import BeachWaves from '../assets/BeachWaves.jpeg';

function About() {
    return (
        <section className='about-section'>
            <div className='about-background'>
                <div className='about-image'>
                    <img className='about-thumbnail' src={AboutMe} alt='me smiling under a tree'/>
                </div>
                <div className='about-details'>
                    <h1>A bit about me.</h1>
                    <h3>My interest in web development started in 2020 after completing a UX Design bootcamp course — turns out I really enjoyed bringing my designs to life with HMTL and CSS! <br />Fast-forward to today, I've had the priviledge to work with multi-disciplinary teams to create full-stack applications using Node.js and Express for the back-end.</h3>
                </div>
            </div>
            <div className='hobby-photography'>
                <p className='discover-photography'><span><svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z" /><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 13a3 3 0 11-6 0 3 3 0 016 0z" /></svg></span>Scroll to see my amateur photography</p>
                <div className='photo-group'>
                    <img className='hobby-photo' src={BeachWaves} alt='Beach waves on a cloudy sky'/>
                    <img className='hobby-photo' src={BeachSunset} alt='Sunset at the beach'/>
                </div>
            </div>
        </section>
    );
}

export default About;