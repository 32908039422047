import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

const encode = (data) => {
    return Object.keys(data).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])).join('&');
}

function Contact() {
    let history = useHistory()
    const [formContent, setFormContent] = useState({
        name: '',
        email: '',
        message:''
    })

    const handleChanges = e => {
        setFormContent({...formContent, [e.target.name]: e.target.value})
    }

    const handleSubmit = e => {
        fetch('/', {
            method:'POST',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            body: encode({'form-name': 'contact', ...formContent})
        })
        .then(() => {
            history.push('/contact/confirmation')
        })
        .catch(error => console.log(error))
        
        
        e.preventDefault();
        setFormContent({
            name: '',
            email: '',
            message: ''
        })
    }

    return (
        <section id='contact' className='contact-section'>
            <h2>Say hello!</h2>
            <p>Get in touch and let me know how I can help.</p>
            <div className='form-container'>
                <form name='contact' onSubmit={handleSubmit} className='contact-form' method='post'>
                    <input type='hidden' name='form-name' value='contact'/>
                    <div className='formfield-container'>
                        <label htmlFor='name' className='form-label'>Name</label>
                        <input 
                            type='text'
                            className='form-input'
                            name='name'
                            id='name'
                            value={formContent.name}
                            onChange={handleChanges}
                            required
                        />
                    </div>
                    <div className='formfield-container'>
                        <label htmlFor='email' className='form-label'>Email</label>
                        <input 
                            type='email'
                            className='form-input'
                            name='email'
                            id='email'
                            value={formContent.email}
                            onChange={handleChanges}
                            required
                        />
                    </div>
                    <div className='formfield-container'>
                        <label htmlFor='message' className='form-label'>Message</label>
                        <textarea 
                            className='message-textarea'
                            name='message'
                            id='message'
                            cols="36" rows="5"
                            value={formContent.message}
                            onChange={handleChanges}
                            required
                        />
                    </div>
                    <button type='submit' className='submit-form'>Send</button>
                </form>
            </div>
        </section>
    );
}

export default Contact;